import consts from "@/consts";
let at = require('lodash/at');//https://lodash.com/docs/4.17.15#at
//let pick = require('lodash/pick');//https://lodash.com/docs/4.17.15#pick
//let pick = require('lodash/get')//https://lodash.com/docs/4.17.15#get
const moment = require('moment-timezone');
// moment.tz.load({});

export {
    formatTimeAgo,
    formatTimestamp, calcUTCTime,
    calcUTCOffset, calcUTCWithOffset, calcTimeToday, calcDateToday,
    arrayFilter_OnlyUnique, arraySplitIntoChunks,
    diffObjects, diffObjectsRecursive, diffObjectsRecursiveExceptions,
    updateObjectByDiff, objectComparison, searchStringOptionsForExceptions,
    searchStringOptionsForFields,
    htmlToElement, htmlToElements,
    addingToArrayIfNotExists, removeFromArrayIfExist,
    getImgOptions, getImageOptions,
    invertColor, calcStartTimeOnDate,
    randomInt, timeLeft, timeAgoAllFormat, daysToDelete,
    print, getParentRouteName, getMainParentRouteName, copyTextToClipboard,
    latLngOutput, getAllValuesObjectByComponents,
    hasFilter, getApiProps, licenseExpiredMonth, isValueExist, sortByName,
    buildIconObject, downloadVideo,
    //timeFormat
}

// var ApiPromise_catch = (error, errors) => {
//     // Error
//     if (error.response) {
//         // console.error(error.response)
//         if(error.response.status == 422){
//             errors = error.response.data.reduce((errors, error) => {
//                 errors[error.field] = [error.message]
//                 return errors
//             }, {})
//         }
//     } else if (error.request) {
//         console.error(error.request);
//     } else {
//         console.error('Error', error.message);
//     }
//     this.toast.error(this.$t("error!"))
//     //console.error(error.config);
// }

function randomInt(min, max)
{
    let rand = min + Math.random() * (max - min + 1);
    return Math.floor(rand);
}

function htmlToElement(html)
{
    var template = document.createElement('template');
    html = html.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = html;
    return template.content.firstChild;
}

function htmlToElements(html)
{
    let template = document.createElement('template');
    template.innerHTML = html;
    return template.content.childNodes;
}

function arraySplitIntoChunks(array_data, chunk_size = 100)
{
    let chunks = []
    while (array_data.length > 0) {
        let chunk = array_data.splice(0, chunk_size)
        chunks.push(chunk)
    }
    return chunks
}

function arrayFilter_OnlyUnique(value, index, self)
{
    return self.indexOf(value) === index;
}

function formatTimeAgo(timeAgo, extend= [])
{
    let time = [
        // {value: Math.floor(timeAgo / (365 * 24 * 3600)), text: 'y'},
        {id: 'day', value: Math.floor(timeAgo / (24 * 3600)), text: 'd'},
        {id: 'hours', value: Math.floor(timeAgo / (3600)), text: 'h'},
        {id: 'minutes', value: Math.floor(timeAgo / 60), text: 'm'},
        {id: 'seconds', value: Math.round(timeAgo), text: 's'},
    ].filter(o => o.value && !extend.includes(o.id))[0]

    return time?.value ? time.value+time.text : '0s'
}

function objectComparison(newObj, oldObj, fields)
{
    let equal = true
    fields.every(fld => {
        let newVal = typeof newObj[fld] == 'object' ? JSON.stringify(newObj[fld]) : newObj[fld]
        let oldVal = typeof oldObj[fld] == 'object' ? JSON.stringify(oldObj[fld]) : oldObj[fld]
        if (newVal != oldVal) {
            equal = false
            return false
        }
    })
    return equal
}

function calcUTCOffset(/*int*/ timezone, /*boolean*/ dst, /*int timestamp*/ time = undefined)
{
    if (typeof time !== "number" && typeof time !== "undefined") {
        console.warn('calcUTCOffset', time, ' unsupported ')
    } else if (time) {
        time *= 1000
    }

    let tz = (timezone >= -2*3600)? "Europe/London" : "America/New_York"
    dst = dst && moment(time).tz(tz).isDST()
    timezone += (dst ? 3600 : 0)

    return timezone
}
function calcUTCWithOffset(/*int*/timezone, /*boolean*/ dst, /*int timestamp*/ time = undefined)
{
    if (typeof time !== "number" && typeof time !== "undefined") {
        console.warn('calcUTCOffset', time, ' unsupported ')
    }
    let timezoneOffset = calcUTCOffset(timezone, dst, time);

    return moment(time * 1000).utcOffset(timezoneOffset / 3600).unix()
}

function formatTimestamp(/*int*/ timezone, /*boolean*/ dst, /*int utc timestamp*/ time, /**/ format = 'YYYY-MM-DD HH:mm:ss')
{
    timezone = calcUTCOffset(timezone, dst, time)
    return moment(time * 1000).utcOffset(timezone / 3600).format(format)
}

function _getStartOfDay(/*int*/ timezone, /*boolean*/ dst, /*int utc timestamp*/ time = undefined)
{
    timezone = calcUTCOffset(timezone, dst, time)

    if (typeof time !== "number" && typeof time !== "undefined") {
        console.warn('calcUTCDateOffset', time, ' unsupported ')
    } else if (time) {
        time *= 1000
    }

    return moment(time).utcOffset(timezone / 3600).startOf('day')
}

function calcTimeToday(/*int*/ timezone, /*boolean*/ dst)
{
    let today = _getStartOfDay(timezone, dst)
    return today.unix()
}

function calcDateToday(/*int*/ timezone, /*boolean*/ dst)
{
    let today = _getStartOfDay(timezone, dst)
    return today.format('YYYY-MM-DD')
}

function calcStartTimeOnDate(/*int*/ timezone, /*boolean*/ dst, /*string or int timestamp*/time)
{
    let date;
    if (typeof time === "string")
    {
        date = moment(time).utcOffset(timezone / 3600, true)
        let time_zone = calcUTCOffset(timezone, dst, date.unix())
        date = moment(time).utcOffset(time_zone / 3600, true)
        return date.unix()
    }

    if (time && typeof time === "number")
    {
        time *= 1000
    } else
    if (time)
    {
        console.warn('calcStartTimeOnDate', time, ' unsupported ')
    }

    date = _getStartOfDay(timezone, dst, time)
    return date.unix()
}

function calcUTCTime(/*int*/ timezone, /*boolean*/ dst, /*string*/datetime)
{
    let time;
    if (typeof datetime !== "string")
    {
        console.warn('calcUTCTime', datetime, typeof datetime, ' unsupported ')
    }
    time = moment(datetime).utcOffset(timezone / 3600, true)
    let time_zone = calcUTCOffset(timezone, dst, time.unix())
    time = moment(datetime).utcOffset(time_zone / 3600, true)
    return time.unix()
}

function diffObjects(oldVal, newVal)
{
    if (typeof newVal == 'object' && typeof oldVal == 'object')
    {
        if (Array.isArray(newVal) && Array.isArray(oldVal))
        {
            return [...newVal]
        } else
        {
            let diffObj
            let oldV = {...oldVal}
            let newV = {...newVal}
            let newKeys = Object.keys(newV)
            newKeys.forEach(key => {
                if (JSON.stringify(newV[key]) != JSON.stringify(oldV[key]))
                {
                    if (!diffObj) diffObj = {}
                    diffObj[key] = newV[key]
                }
            })
            // Object.keys(oldV).forEach(key => {
            //     if(!newKeys.includes(key)){
            //         if(!diffObj) diffObj = {}
            //         diffObj[key] = null
            //     }
            // })
            return diffObj
        }
    } else
    if (newVal !== oldVal) {
        return newVal
    }
    return undefined
}

function diffObjectsRecursive(oldVal, newVal)
{
    //???
    //if(newVal == oldVal){ return undefined }
    if (typeof newVal !== typeof oldVal)
    {
        return newVal
    } else
    if (typeof newVal == 'object')
    {
        let diffObj
        if (Array.isArray(newVal))
        {
            return [...newVal]
        } else
        {//objects
            let oldV = {...oldVal}
            let newV = {...newVal}
            Object.keys(newV).forEach(key => {
                let keyVal = diffObjectsRecursive(oldV[key], newV[key])
                if (keyVal !== undefined)
                {
                    if (!diffObj) diffObj = {}
                    diffObj[key] = keyVal
                }
            })
        }
        return diffObj
    } else
    if (newVal !== oldVal)
    {
        return newVal
    }
    return undefined
}

function diffObjectsRecursiveExceptions(oldVal, newVal, fields4DiffRecursive = [])
{
    if ((typeof newVal != 'object' || typeof oldVal != 'object') || (Array.isArray(newVal) || Array.isArray(oldVal)))
    {
        return undefined
    }

    //only objects
    let oldValR = {},
        newValR = {},
        oldValNR = {...oldVal},
        newValNR = {...newVal}

    let keysRecursive = Object.keys(newVal).filter(key => fields4DiffRecursive.find(fld => fld == key))
    keysRecursive.forEach(k => {
        oldValR[k] = oldValNR[k]
        newValR[k] = newValNR[k]
        delete oldValNR[k]
        delete newValNR[k]
    })
    let newNR = diffObjects(oldValNR, newValNR)
    let newR = diffObjectsRecursive(oldValR, newValR)
    let result = {...newNR, ...newR}
    return Object.keys(result).length ? result : undefined
}

function updateObjectByDiff(baseObj, newObj)
{
    let diff = diffObjects(baseObj, newObj) || {}
    Object.entries(diff).map(([name, val]) => {
        baseObj[name] = val
    })
}

function searchStringOptionsForExceptions(obj, exceptions = [])
{
    let str = ''
    let value = Object.values(Object.fromEntries(Object.entries(obj).filter(([key]) => !exceptions.includes(key))));
    value.forEach(v => {
        if (typeof v == 'string' || typeof v == 'number')
        {
            str += (v + ';')
        }
        if (typeof v == 'object' && !Array.isArray(v) && v !== null)
        {
            str += searchStringOptionsForExceptions(v)
        }
    })
    return str
}

function searchStringOptionsForFields(obj, fields = [], search='', extended=['commands'])
{
    // let date = moment(search)
    // if(date.isValid()){
    //     search = date.format('YYYY-MM-DD');
    // }
    fields = fields.filter(f => !extended.includes(f))
    let searchValues = at(obj, fields).filter(v => {
        if(typeof v == 'object' && v == null){
            return false
        } else
        if(typeof v == 'boolean') {
            return  false
        } else
        if( v === undefined) {
            return false
        } else
        if(v == ''){
            return false
        }
        return true
    })
    searchValues =
        searchValues.map(v => {
            if(typeof v == 'object') v = JSON.stringify(v)
            return v
        })
        .join(';')
        .toLowerCase()
    return searchValues.includes(search.toLowerCase())
}

function addingToArrayIfNotExists(arr, value)
{
    if (typeof value === 'string')
    {
        let inArray = !!arr.find(e => e == value)
        if (!inArray) {
            arr.push(value)
        }
    }
    if (Array.isArray(value))
    {
        value.forEach(v => {
            let inArray = !!arr.find(e => e == v)
            if (!inArray) {
                arr.push(v)
            }
        })
    }
    return arr
}

function removeFromArrayIfExist(arr, value)
{
    if (typeof value === 'string')
    {
        arr = arr.filter(v => v != value)
    }
    if (Array.isArray(value))
    {
        value.forEach(v => {
            arr = arr.filter(e => e != v)
        })
    }
    return arr
}

function getImgOptions(src)
{
    return new Promise(function (res) {
        let image = new Image();
        image.src = src
        image.onload = function () {
            res({iconSize: [image.width, image.height], iconUrl: src})
        }
    })
}

async function getImageOptions(icon)
{
    let marker = null;
    let src = '';

    if (icon?.url)
    {
        src = icon?.url
    } else
    if (icon?.assetPath)
    {
        src = require('@/assets' + icon?.assetPath)
    } else
    if (icon?.name)
    {
        src = require('@/assets/icons/' + icon?.name + '.svg')
    } else
    {
        console.error('getImageOptions', icon)
        return null
    }

    await getImgOptions(src).then(val => {
        if (!val.iconSize.length || (val.iconSize.length && (!val.iconSize[0] || !val.iconSize[1])))
        {
            console.error('getImgOptions', val)
        } else
        {
            marker = {icon: {...val}}
        }
    }).catch((error) => {
        console.error('getImgOptions', error)
    })
    return marker;
}

function invertColor(hexTripletColor)
{
    let color = hexTripletColor;
    color = color.substring(1);           // remove #
    color = parseInt(color, 16);          // convert to integer
    color = 0xFFFFFF ^ color;             // invert three bytes
    color = color.toString(16);           // convert to hex
    color = ("000000" + color).slice(-6); // pad with leading zeros
    color = "#" + color;                  // prepend #
    return color;
}

function timeLeft(time_left, seconds = true)
{
    if(!time_left && time_left !== 0) return null
    let params = [
        // {value: Math.floor(time_left / (365 * 24 * 3600)), text: 'y'},
        {value: Math.floor(time_left / (24 * 3600)), text: 'days'},
        {value: Math.floor(time_left / (3600)), text: 'hours'},
        {value: Math.floor(time_left / 60), text: 'minutes'},
    ]
    if(seconds) {
        params.push({value: Math.floor(time_left), text: 'seconds'})
    }
    return params.filter(o => o.value)[0] || {value: 0, text: 'seconds'}
}
function timeAgoAllFormat(time_ago, showSeconds= false) {
    let days = Math.floor(time_ago / (24 * 60 * 60))
    let hours = Math.floor((time_ago / 60 / 60) % 24);
    let minutes = Math.floor((time_ago / 60) % 60);
    let seconds = Math.floor((time_ago) % 60);
    return (days ? days + 'days ' : '')
        + (hours ? hours + 'h ' : '')
        + (minutes ? minutes + 'm ' : !showSeconds ? '0m' : '')
        + (showSeconds ? (seconds ? (seconds + 's') : '0s') : '');
}

function daysToDelete(time)
{
    if (typeof time !== "number") return ''
    let timeLeft = time + consts.recyclebin_time_left - (Date.now() / 1000)//this.getTimeToday
    return Math.floor(timeLeft / (24 * 3600))
}

// function timeFormat(value)
// {
//     return ('0' + value).slice(-2)
// }

function print(vl)
{
    if (vl === null) return vl
    switch (typeof vl)
    {
        case 'undefined':
            return vl;
        case 'object':
            return JSON.stringify(vl, null, ' ');
        default:
            return ''+vl
    }
}
function getParentRouteName(routeName) {
    let nesting = (''+routeName).split('.').slice(0, -1).join('.')
    return nesting || routeName
}
function getMainParentRouteName(routeName) {
    return (''+routeName+'.').split('.')[0]
}

function copyTextToClipboard(text) {
    if (navigator.clipboard) {
        return navigator.clipboard.writeText(text)
    }
    Promise.reject(null)
}
function latLngOutput(latLng) {
    return 'lat: ' + latLng.lat + ', ' + 'lon: ' + latLng.lng
}
function getAllValuesObjectByComponents(components, obj) {
    return components.reduce((out, c) => {
        if(obj[c.component]){
            let values = obj[c.component]()
            let keys =  Object.keys(values)
            keys.forEach(k => {
                if(Array.isArray(values[k])){
                    out[k] = [...(out[k] || []), ...values[k]]
                } else if(typeof values[k] === "object" && values[k] !== null) {
                    out[k] = {...(out[k] || {}), ...values[k]}
                } else {
                    out[k] = values[k]
                }
            })
        }
        return out
    }, {})
}
function hasFilter(args) {
    if (args?.page) return true
    let keys = Object.keys(args || {})
    let regexp = /^filter\[.+\]$/;
    return !!keys.map(k => (regexp).test(k)).filter(k=>k).length
}
function getApiProps(obj, args) {
    if (args?.lite) obj += '_lite'
    //delete args.lite
    let params = consts.api[obj]
    let fields = Array.isArray(params?.fields) ? params?.fields?.join() : (params?.fields || '')
    let expand = Array.isArray(params?.expand) ? params?.expand?.join() : (params?.expand || '')
    return {
        fields,
        expand,
        ...args
    }
}
function licenseExpiredMonth() {
    let m = moment()
    let days = m.format('DD')
    if(days > 25) m.add('month', 1)
    return m.format('Y-MM')
}

function isValueExist(value) {
    if(value === undefined) {
        return false;
    } else
    if(value === null){
        return false;
    } else
    if(value === '') {
        return false;
    } else
    if(Array.isArray(value)){
        return !!value.length
    } else
    if(typeof value == 'object') {
        return !!Object.keys(value)?.length
    }
    return true;
}
function buildIconObject(image, type, defaultIcon='video-event_other', delimiter='_'){
    let name = image?.name || (typeof image == 'string' && image)
    name = !name ? defaultIcon : (type ? type + delimiter + name : name)
    return {name, rb: image?.rb, rt: image?.rt, lb: image?.lb, lt: image?.lt}
}

const collator = new Intl.Collator();
function sortByName (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

function downloadVideo(files){
    //need Allow the site to show existing windows and use redirects in browser settings
    if(!files?.length) return false;
    files.forEach(v => {
        if(v.urls && v.urls.length){
            v.urls.forEach(u => {
                window.open(u?.url_download || u?.url)
            })
        } else {
            window.open(v?.url_download || v?.url)
        }
    })
}