<template>
    <div class="list__symbol symbol">
        <div class="symbol__image-box" :style="{color: shipmentIconColor}">
            <SvgIcon style="height: 100%; width: 100%" :name="shipmentIcon" class="icon svg-icon-shipment-marker" v-if="shipmentIcon"/>
            <span class="description flex align-items-canter justify-content-center text-align-center" v-else>
                {{$t('Shipment.No type')}}
            </span>
        </div>
    </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    name: "ShipmentList_item_icons",
    props: {
        shipmentId: [String, Number],
        defaultIcon: {
            type: String,
            default: () => 'shipment__mail'
        },
    },
    components: {
    },
    computed:{
        ...mapGetters([
            "getShipments",
            "getShipmentTypes",
        ]),
        shipment(){
            return this.getShipments?.find(s => s.id == this.shipmentId)
        },
        shipmentIcon(){
            return this.shipment?.type ? 'shipment__'+this.shipment?.type : null
        },
        shipmentIconColor(){
            return this.shipment?.icon_color || '#003B79'
        },
    }
}
</script>

<style scoped lang="scss">
.symbol {
    display: flex;
    align-items: center;

    &__image-box {
        position: static !important;
    }
}
</style>