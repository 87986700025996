<template>
    <custom-modal v-if="modalWindows?.accessCheckPopup" v-bind="modalWindows?.accessCheckPopup?.props">
        <p class="size-14 flex justify-content-center align-items-canter" v-if="!oldUserPinCode">{{ $t('popup.You need to set a Pin code') }}</p>
        <template v-else>
<!--            <p class="size-14 text-align-center">{{ $t('popup.To Send Command Enter 6 Digit Pin') }}</p>-->
<!--            <br>-->
            <FieldGroup_input
                :field-group_class="'mb__16'"
                :id="'pinCode'"
                :label="$t('User.Pin Code')"
                :placeholder="$t('errors.Enter 6 digit pin code')"
                :error="v$?.cmdPinCode?.$errors[0]?.$message"
                v-model.trim.lazy="cmdPinCode"
            />
        </template>
    </custom-modal>

    <custom-modal v-if="modalWindows?.setPinCode" v-bind="modalWindows?.setPinCode?.props">
        <SectionLoading v-if="modalWindows?.loading"/>
        <template v-else>
            <UserEdit_CommandsPinCode :userId="getAppUser?.id"/>
        </template>
    </custom-modal>
</template>

<script>
import debounce from "lodash/debounce";
import useVuelidate from "@vuelidate/core";
import {helpers, maxLength, minLength, requiredIf, sameAs} from "@vuelidate/validators";
import {mapActions, mapGetters} from "vuex";
import {computed, defineAsyncComponent} from "vue";
import {useToast} from "vue-toastification";

export default {
    name: "CommandsConfirmationPINCode",
    emits: ['clearAction'],
    props: {
        action: Function,
        onlyEdit: Boolean,
    },
    components: {
        UserEdit_CommandsPinCode: defineAsyncComponent(() => import("@/components/users/sectionInfo/UserEdit_CommandsPinCode.vue")),
    },
    data() {
        return {
            cmdPinCode: '',
            v$: useVuelidate(),
            toast: useToast(),
            modalWindows: {},
            user: {},
            errors: {},
        }
    },
    validations() {
        return {
            cmdPinCode: {
                required: helpers.withMessage(this.$t('errors.required', {name: this.$t('User.Pin Code')}), requiredIf(this.modalWindows?.accessCheckPopup)),
                minLength: helpers.withMessage(this.$t('errors.minLength', {count: 6}), minLength(6)),
                maxLength: helpers.withMessage(this.$t('errors.maxLength', {count: 6}), maxLength(6)),
                sameAsPassword: helpers.withMessage(this.$t('errors.sameAsPassword', {value: this.$t('User.Pin Code')}), sameAs(this.modalWindows?.accessCheckPopup ? this.oldUserPinCode : '')),
            },
            user: {
                cmdPinCode: {
                    old_pin_code: {
                        required: helpers.withMessage(this.$t('errors.required', {name: this.$t('User.Old Pin Code')}), requiredIf(this.modalWindows?.setPinCode && this.oldUserPinCode)),
                        minLength: helpers.withMessage(this.$t('errors.minLength', {count: 6}), minLength(6)),
                        maxLength: helpers.withMessage(this.$t('errors.maxLength', {count: 6}), maxLength(6)),
                        sameAsPassword: helpers.withMessage(this.$t('errors.sameAsPassword', {value: this.$t('User.Old Pin Code')}), sameAs((this.modalWindows?.setPinCode && this.oldUserPinCode) ? this.oldUserPinCode : '')),
                    },
                    new_pin_code: {
                        required: helpers.withMessage(this.$t('errors.required', {name: this.$t('User.New Pin Code')}), requiredIf(this.modalWindows?.setPinCode)),
                        minLength: helpers.withMessage(this.$t('errors.minLength', {count: 6}), minLength(6)),
                        maxLength: helpers.withMessage(this.$t('errors.maxLength', {count: 6}), maxLength(6)),
                    },
                    repeat_pin_code1: {
                        required: helpers.withMessage(this.$t('errors.required', {name: this.$t('User.Repeat New Pin Code')}), requiredIf(this.modalWindows?.setPinCode)),
                        minLength: helpers.withMessage(this.$t('errors.minLength', {count: 6}), minLength(6)),
                        maxLength: helpers.withMessage(this.$t('errors.maxLength', {count: 6}), maxLength(6)),
                        sameAsPassword: helpers.withMessage(this.$t('errors.sameAsPassword', {value: this.$t('User.Repeat New Pin Code')}), sameAs(this.user?.cmdPinCode?.new_pin_code)),
                    },
                },
            }
        }
    },
    computed: {
        ...mapGetters([
            "getAppUser",
        ]),
        oldUserPinCode() {
            return this.getAppUser?.cmd_pin_code
        },
    },
    provide() {
        return {
            user: computed(() => this.user),
            _user: computed(() => this.getAppUser),
            errors: computed(() => this.errors)
        }
    },
    watch: {
        user: {
            handler: function () {
                this.errors = {}
                this.debounceValidate()
                // this.errors = this.v$.$errors
                //     .reduce((errors, e) => {
                //         if(!errors[e.$propertyPath]) errors[e.$propertyPath] = []
                //         errors[e.$propertyPath].push(e.$message)
                //         return errors
                //     },{})
            },
            deep: true
        },
    },
    methods: {
        ...mapActions([
            "saveAppUser",
        ]),
        onAccessCheckPopup() {
            this.modalWindows = {
                'accessCheckPopup': {
                    props:{
                        title: this.$t('popup.Enter The Command PIN Code'),
                        modalClass: 'modal-600',
                        btnOkClass: 'btn-ok button_theme_neutral',
                        onceClick: false,
                        btnOkText: !this.oldUserPinCode ? (this.$t('Set') + ' ' + this.$t('User.Command Pin Code')) : null,
                        buttons:[
                            {id: 'ok', fn: () => (!this.oldUserPinCode ? this.onSetPinCode() : this.onOpenAction())},
                            {id: 'cancel', fn: () => this.$emit('clearAction')},
                        ]
                    }
                }
            }
        },
        async onOpenAction() {
            const isValid = await this.v$.cmdPinCode.$validate()
            if(!isValid){
                console.warn(this.v$.$errors)
                return false // stop here if form is invalid
            }
            this.$emit('clearAction');
            this.action();
        },
        onSetPinCode(){
            this.modalWindows = {
                'setPinCode':{
                    props:{
                        title: this.$t('Set') + ' ' + this.$t('User.Command Pin Code'),
                        modalClass: 'modal-600',
                        btnOkClass: 'btn-ok button_theme_neutral',
                        btnOkText: this.$t('Save'),
                        onceClick: false,
                        buttons:[
                            {id: 'ok', fn: () => this.setPinCode()},
                            {id: 'cancel', fn: () => this.$emit('clearAction')},
                        ]
                    }
                }
            }
        },
        async setPinCode() {
            const isValid = await this.v$.user.$validate()
            if(!isValid){
                console.warn(this.v$.$errors)
                return false // stop here if form is invalid
            }
            if(!this.user?.cmdPinCode || !this.user?.cmdPinCode?.new_pin_code) {
                console.warn('cmdPinCode', this.user?.cmdPinCode)
                this.toast.error("Empty new pin Code")
                return false;
            }
            this.modalWindows.loading = true
            this.saveAppUser({id: this.user?.id, cmd_pin_code: this.user?.cmdPinCode?.new_pin_code})
                .then((responce) => {
                    if(responce?.status < 400 && !responce?.data?.error && !responce?.data?.errors){
                        this.toast.success("success!")

                        if(!this.onlyEdit) {
                            this.onAccessCheckPopup()
                        } else {
                            this.$emit('clearAction')
                        }

                        return true
                    } else {
                        this.toast.error("error!")
                        return true
                    }
                })
                .catch((error) => {
                    // Error
                    let msg = error?.data?.message || error?.message || this.$t("error!")
                    this.toast.error(msg)
                    console.error(error)
                    //console.error(error.config);
                    this.v$.$validate()
                    return false
                })
        },
        updateUserEdit() {
            this.user = {id: this.getAppUser?.id}
        }
    },
    created() {
        this.updateUserEdit()
        this.debounceValidate = debounce(() => {
            this.v$.$touch()
        }, 300)
    },
    mounted() {
        this.onlyEdit ? this.onSetPinCode() : this.onAccessCheckPopup()
    }
}
</script>

<style scoped lang="scss">

</style>