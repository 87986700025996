// import Vue from 'vue'
// import consts from "@/consts"
// import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
const changedField = 'changed__time';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        shipmentsAlertsPresetsFullLoad: false,
        shipmentsAlertsPresetsLiteLoad: false,
        shipmentsAlertsPresets: [],
    },
    actions: {
        fetchShipmentsAlertsPresets/*all*/({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchShipmentsAlertsPresets', time: Date.now() / 1000})

                const params = getApiProps('shipmentsAlertsPresets', args)
                this.$api.shipmentsAlertsPresets.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error && !response.data.errors) {
                            commit('updateShipmentsAlertsPresets', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        commit('setShipmentsAlertsPresetsLiteLoad', true)
                        commit('setShipmentsAlertsPresetsFullLoad', true)
                        dispatch('setLastCall', {name: 'fetchShipmentsAlertsPresets', inprogress: false})
                    });
            })
        },
        fetchShipmentsAlertsPreset/*all*/({dispatch, commit, getters}, id) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchShipmentsAlertsPreset', time: Date.now() / 1000})

                const params = getApiProps('shipmentsAlertsPresets')
                this.$api.shipmentsAlertsPresets.find(id,{...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error && !response.data.errors) {
                            commit('updateShipmentsAlertsPreset', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchShipmentsAlertsPreset', inprogress: false})
                    });
            })
        },

        saveShipmentsAlertsPreset({dispatch}, alertPreset) {
            let fn = (alertPreset.id) ? 'updateShipmentsAlertsPreset' : 'createShipmentsAlertsPreset'
            return dispatch(fn, alertPreset);
        },
        createShipmentsAlertsPreset({commit}, alertPreset) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('shipmentsAlertsPresets')
                this.$api.shipmentsAlertsPresets.create(alertPreset, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error && !response.data.errors) {
                            commit('updateShipmentsAlertsPreset', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateShipmentsAlertsPreset({commit}, alertPreset) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('shipmentsAlertsPresets')
                this.$api.shipmentsAlertsPresets.update(alertPreset.id, alertPreset, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error && !response.data.errors) {
                            commit('updateShipmentsAlertsPreset', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteShipmentsAlertsPreset({commit}, alertPresetId) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('shipmentsAlertsPresets')
                this.$api.shipmentsAlertsPresets.delete(alertPresetId, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data.error)) {
                            if (!response.data) commit('deleteShipmentsAlertsPreset', alertPresetId)
                            else commit('updateShipmentsAlertsPreset', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations: {
        setShipmentsAlertsPresetsFullLoad(state, FullLoad) {
            state.shipmentsAlertsPresetsFullLoad = state.shipmentsAlertsPresetsFullLoad || FullLoad
        },
        setShipmentsAlertsPresetsLiteLoad(state, LitaLoad) {
            state.shipmentsAlertsPresetsLiteLoad = state.shipmentsAlertsPresetsLiteLoad || LitaLoad
        },

        setShipmentsAlertsPresets(state, nShipmentsAlertsPresets) {
            nShipmentsAlertsPresets = nShipmentsAlertsPresets.map(u => {
                if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                return u //Object.freeze(u)
            })
            nShipmentsAlertsPresets.sort(sortByName)
            state.shipmentsAlertsPresets = nShipmentsAlertsPresets
        },

        updateShipmentsAlertsPresets(state, nShipmentsAlertsPresets) {
            if (!state.shipmentsAlertsPresets.length) {
                nShipmentsAlertsPresets = nShipmentsAlertsPresets.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nShipmentsAlertsPresets.sort(sortByName)
                state.shipmentsAlertsPresets = nShipmentsAlertsPresets
                // const chunks = arraySplitIntoChunks(nShipmentsAlertsPresets)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.shipmentsAlertsPresets.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nShipmentsAlertsPresets.forEach(function (nAlertPreset) {
                if (nAlertPreset?.name) nAlertPreset.name_ = nAlertPreset.name.toLocaleLowerCase()
                let i = state.shipmentsAlertsPresets.findIndex(u => (u.id == nAlertPreset.id))
                if (i < 0) {
                    state.shipmentsAlertsPresets.push(nAlertPreset) //(Object.freeze(nAlertPreset))
                } else
                if (/*!state.shipmentsAlertsPresetsFullLoad || */state.shipmentsAlertsPresets[i][changedField] != nAlertPreset[changedField]) {
                    updateObjectByDiff(state.shipmentsAlertsPresets[i], nAlertPreset)
                    // delete nAlertPreset.id
                    // nAlertPreset = {...state.shipmentsAlertsPresets[i], ...nAlertPreset}
                    // state.shipmentsAlertsPresets[i] = nAlertPreset //Object.freeze(nAlertPreset)
                }
            })

        },
        filterShipmentsAlertsPresets(state, nShipmentsAlertsPresets) {
            // let Ids = state.shipmentsAlertsPresets.map(u=> u.id)
            let nIds = nShipmentsAlertsPresets.map(u => u.id)
            let removedIds = state.shipmentsAlertsPresets.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.shipmentsAlertsPresets.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.shipmentsAlertsPresets.splice(i, 1)
                }
            })
        },
        updateShipmentsAlertsPreset(state, nAlertPreset) {
            if (nAlertPreset?.name) nAlertPreset.name_ = nAlertPreset.name.toLocaleLowerCase()
            let i = state.shipmentsAlertsPresets.findIndex(u => (u.id == nAlertPreset.id))
            if (i < 0) {
                state.shipmentsAlertsPresets.push(nAlertPreset) //(Object.freeze(nAlertPreset))
            } else
            if (/*!state.shipmentsAlertsPresetsFullLoad || */state.shipmentsAlertsPresets[i][changedField] != nAlertPreset[changedField]) {
                updateObjectByDiff(state.shipmentsAlertsPresets[i], nAlertPreset)
                // delete nAlertPreset.id
                // nAlertPreset = {...state.shipmentsAlertsPresets[i], ...nAlertPreset}
                // state.shipmentsAlertsPresets[i] = nAlertPreset //Object.freeze(nAlertPreset)
            }
        },
        deleteShipmentsAlertsPreset(state, alertPresetId) {
            let i = state.shipmentsAlertsPresets.findIndex(u => (u.id == alertPresetId))
            if (i != -1) {
                state.shipmentsAlertsPresets.splice(i, 1)
            }
        },

        clearShipmentsAlertsPresets(state) {
            state.shipmentsAlertsPresets = []
            state.shipmentsAlertsPresetsFullLoad = false
        },
    },
    getters: {
        isShipmentsAlertsPresetsFullLoad(state) {
            return state.shipmentsAlertsPresetsFullLoad
        },
        isShipmentsAlertsPresetsLiteLoad(state) {
            return state.shipmentsAlertsPresetsLiteLoad
        },
        getShipmentsAlertsPresets(state) {
            return state.shipmentsAlertsPresets
        },
    }
}
