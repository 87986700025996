<template>
    <ul class="tabs-control">
        <template v-for="item in list" :key="item.name">
            <li class="tabs-control__item"
                :class="[modelValue == item[field_value] && 'tabs-control-item--active', item.icon && 'tabs-control__with-icon']"
                @click="!disabled ? $emit('update:modelValue', item[field_value]) : false"
            >
                <div class="tabs-control__icon-wrap" v-if="item.icon">
                    <SvgIcon :name="item.icon" class="icon"/>
                </div>
                <div>
                    {{ item[field_id] }}
                </div>
            </li>
        </template>
    </ul>
</template>

<script>
import SvgIcon from "@/components/_base/SvgIcon";
export default {
    name: "TabsControl",
    components: {SvgIcon},
    emits:['update:modelValue'],
    props:{
        modelValue: String,
        list:{
            type: [Array, Object],
            default: () => []
        },
        field_id: {
            type: String,
            default: () => 'name'
        },
        field_value: {
            type: String,
            default: () => 'value'
        },
        disabled: {
            type: Boolean,
            default: () => false
        }
    }
}
</script>

<style lang="scss" scoped>
.tabs-control{
    display: flex;
    align-items: center;

    &__item{
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 10px;
        border-bottom: solid 1px var(--border-color);
        @include font(12px, normal, 500, none, normal, var(--color-default));
        cursor: pointer;
        &:not(:first-child):after{
            content: '';
            display: block;
            width: 1px;
            height: 50%;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background: var(--border-color);
        }

        &.tabs-control-item--active {
            font-weight: 700;
            color: var(--color-default);
            &:before{
                content: '';
                display: block;
                width: 100%;
                height: 3px;
                background: var(--border-color--active);
                position: absolute;
                left: 0;
                bottom: -1px;
            }
        }
    }

    &__with-icon {
        flex-direction: column;
        gap: 5px;
        white-space: nowrap;
        border: none;
        &:not(:first-child):after {
            content: none;
        }
        &.tabs-control-item--active {
            position: relative;
            color: var(--tabs-active-color-with-icon);
            z-index: 2;
            &:before {
                width: 100%;
                height: 100%;
                opacity: var(--tabs-activebg-opasity-with-icon);
                background: var(--tabs-activebg-with-icon);
                box-shadow: var(--tabs-active-box-shadow-with-icon);
                border-radius: 8px;
                z-index: -1;
            }
        }
    }
}
.shipment-tabs {
    width: 100%;
    .tabs-control {
        $this: &;
        &__icon-wrap {
            background: white;
            width: 60px;
            height: 60px;
            padding: 10px;
            border-radius: 8px;
            position: relative;
            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                background: #F9FBFF;
                position: absolute;
                left: 0;
                top: 0;
                border-radius: 8px;
                opacity: .7;
                z-index: 1;
            }
            .icon {
                width: 100%;
                height: 100%;
            }
        }

        &__item {
            &.tabs-control-item--active {
                @include font(12px, normal, 700, none, normal, var(--color-default));
                &:before {
                    content: none;
                }
                .tabs-control__icon-wrap {
                    box-shadow: 0px 2px 4px 0px rgba(69, 122, 180, 0.20);
                    &:before {
                        content: none;
                    }
                }
            }
        }

    }
}
.shipment-tabs-50 {
    .tabs-control{
        &__icon-wrap {
            width: 50px;
            height: 50px;
        }
    }
}
</style>