<template>
    <section class="section__body">

        <!--- Navigation --->
        <ShipmentList_navigation />

        <!--- Container --->
        <section class="selected__container plr__12 pb__12" ref="list_container">

            <DynamicScroller
                ref="list"
                class="list dynamic-scroller-list-units"
                :items="shipmentsAndGroups"
                :key-field="'xid'"
                :min-item-size="44"
                :buffer="600"
            >
                <template #before>
                    <!-- start -->
                </template>

                <template v-slot="{ item, index, active }">
                    <DynamicScrollerItem
                        :item="item"
                        :active="active"
                        :size-dependencies="[]"
                        :data-index="index"
                        :data-active="active"
                        :class="item.type == 'group' ? 'list-units-scroller-group-height' : 'list-units-scroller-item-height'"
                    >
                        <component
                            :is="'ShipmentList_'+item.type"
                            v-bind="item"
                        />
                    </DynamicScrollerItem>
                </template>

                <template #after>
                    <!--  end -->
                    <SectionLoading v-if="!ready || (ready && !isShipmentsLiteLoad)"/>
                    <section class="section section_type_loader loader loader_status_active"
                             v-show="isShipmentsLiteLoad && !filteredShipmentsIds.length"
                    >
                        <span class="loader__text">{{ $t('text.No data') }}</span>
                    </section>
                </template>
            </DynamicScroller>

        </section>
    </section>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import {computed, defineAsyncComponent} from "vue";

    import ShipmentList_navigation from "@/components/shipment/ShipmentList_navigation.vue";

    //intuit
    import ShipmentList_item from "@/components/shipment/ShipmentList_item.vue"
    import {useToast} from "vue-toastification";

    //prometheus

    //import TabsControl from "@/components/_base/TabsControl";
    // import {getMainParentRouteName} from "@/lib/lib";


    export default {
        name: "ShipmentList",
        components: {
            // VueAutoVirtualScrollList,
            TabsControl: defineAsyncComponent(() => import("@/components/_base/TabsControl.vue")),
            ShipmentList_navigation,

            //intuit
            ShipmentList_item,//: defineAsyncComponent(() => import ("@/components/shipments/ShipmentsList_shipment.intuit.vue")),

            //prometheus
        },
        data() {
            return {
                ready: false,
                interval: null,
            }
        },
        provide(){
            return {
                setRoute: computed(() => this.setRoute),
                filteredShipmentsIds: computed(() => this.filteredShipmentsIds),
                toast: useToast(),
            }
        },
        computed: {
            ...mapGetters([
                "getAppUserId",
                "getShipments",
                "getUserStorageKey",
                "isShipmentsLiteLoad",
                "getUserStorageKey",
                "getDateToday",
            ]),
            routeName(){
                return this.$route.name
            },
            mainRouteName(){
                return this.$route.meta?.mainRouteName
            },
            localStorageKey() {
                return this.getUserStorageKey + '.shipmentId'
            },
            shipments() {
                return [
                    ...this.getShipments,
                ]
            },
            shipmentsIds() {
                let shipments = this.shipments
                shipments.sort((a, b) => {
                    if(this.routeQueryFilter?.status === 'completed') {
                        let aRoute = a.routes.find(r => r.stop_order == 1)?.arrival_time
                        let bRoute = b.routes.find(r => r.stop_order == 1)?.arrival_time
                        return bRoute - aRoute
                    }
                    return b.shipping_time - a.shipping_time
                })
                return this.shipments.map(s => s.id)
            },
            isShipmentsPage(){
                return this.mainRouteName == this.$consts.routerNames.shipments.main
            },
            routeFilter(){
                return this.$route.params
            },
            routeQueryFilter(){
                return this.$route.query
            },
            activeShipmentId(){
                return !this.ready ? null : this.routeFilter.shipmentId || null
            },
            shipments_length(){
                return this.shipments.length
            },
            showMapInfo(){
                return this.$route.meta.showMapInfo
            },
            setRoute() {
                return this.$route.meta?.setRoute
            },
            filteredShipmentsIds() {
                let queryFilter = this.routeQueryFilter
                //let routeFilter = this.routeFilter

                let shipments = this.shipments.filter(this.filterShipments);
                    // .filter(u => u?.access_right >= accessRight.read)

                if(queryFilter.type) {
                    shipments = shipments.filter(u => u.type === queryFilter.type)
                }
                if(queryFilter.mode) {
                    shipments = shipments.filter(u => u.mode === queryFilter.mode)
                }
                if (queryFilter.name) {
                    let name = queryFilter.name?.toLocaleLowerCase()
                    shipments = shipments.filter(u => !!u.name_?.includes(name))
                }

                return shipments.map(u => u.id)
            },
            shipmentsAndGroups(){
                if(!this.ready) return []
                let groupId = -1
                let shipments = this.shipmentsIds
                    .filter(sId => this.filteredShipmentsIds.includes(sId))
                    .map(sId => {
                        let type = 'item';
                        return {xid: `${groupId}-${sId}`, groupId, type, shipmentId: sId}
                    })

                let count = this.shipments.length
                if(count != this.shipmentsIds.length) count = this.shipmentsIds.length+'/'+count

                return [
                    //{xid: `${groupId}`, groupId, type: 'group', title: this.$t('text.Shipments'), count},
                    ...shipments,
                ]
            },
            setShipmentActive() {
                return this.ready
                    && this.isShipmentsLiteLoad
                    && this.mainRouteName === this.$consts.routerNames.shipment.main
                    && (this.routeQueryFilter?.status || 'active')
                    //&& (!this.activeShipmentId && this.routeName !== this.$consts.routerNames.shipment.editView)
            },
        },
        watch: {
            setShipmentActive: {
                handler: function (newVl, oldVal) {
                    if(newVl && newVl !== oldVal) {
                        this.setActiveShipment()
                    }
                },
                immediate: true
            },
            activeShipmentId: {
                handler (newVl /*, oldVl*/) {
                    //if(this.$isDevelopment) console.log("isActive", newVl, oldVl)
                    //ToDo reset filter
                    if(newVl) {
                        this.$nextTick().then(() => this.scrollToUnit())
                        //setTimeout(() => this.scrollToUnit(), 300)
                    }
                },
                immediate: true
            },
        },
        methods: {
            ...mapActions([
            ]),
            ...mapMutations([
            ]),
            filterShipments(shipment) {
                let completed = this.$consts?.shipment?.extendedShipmentByStatus?.includes(shipment.status)
                let shipmentActualEndTimeDate = shipment?.completed__time?.date
                return shipment.id && (this.routeQueryFilter?.status === 'completed' ? completed : (!completed || (completed && this.getDateToday <= shipmentActualEndTimeDate)))
            },
            scrollToUnit(){
                //let xid = '-1'+this.activeShipmentId
                let scrollTo = this.shipmentsAndGroups.findIndex(i => i.shipmentId == this.activeShipmentId)
                let height = this.$refs?.list_container?.clientHeight
                let itemSize = Object.values(this.$refs.list?.vscrollData?.sizes)[1]
                if (height && itemSize) {
                    scrollTo -= Math.round((height / itemSize) / 2)
                    if (scrollTo < 0) scrollTo = 0
                }
                this.$refs?.list?.scrollToItem(scrollTo)
            },
            setActiveShipment() {
                let localeStorageKey = this.getUserStorageKey + '.shipmentId'
                let shipmentId = this.shipmentId || localStorage.getItem(localeStorageKey)
                let shipments = this.getShipments.filter(this.filterShipments);

                shipments?.sort((a,b) => {
                    let aEnd = a?.routes?.find(r => r.stop_order == 1)
                    let bEnd = b?.routes?.find(r => r.stop_order == 1)
                    return this.routeQueryFilter?.status === 'completed' ?
                        bEnd.actual_arrival_time - aEnd.actual_arrival_time  :
                        b.shippingTime - a.shippingTime
                })

                let shipment = shipments.find(s => s.id == shipmentId) || shipments[0]
                shipmentId = shipment?.id
                if(shipmentId) this.setRoute.shipmentId(this.$route, this.$router, shipmentId)
            }
        },
        created() {
            //if(this.$isDevelopment) console.log('ShipmentsList created')
        },
        mounted(){
            //if(this.$isDevelopment) console.log('ShipmentsList mounted')
            setTimeout(() => this.ready = true, this.$consts.timeoutReadyComponent)
            //setTimeout(() => {this.ready = true;}, 500)
        },
        updated() {
        },
        activated() {
            // if(this.getShipments.length && this.isShipmentsPage) {
            //     this.setActiveShipment()
            // }

            // this.$nextTick().then(() => {this.ready = true})
            // setTimeout(() => this.ready = true, this.$consts.timeoutReadyComponent)
        },
        deactivated() {
            this.ready = false
        },
    }
</script>

<style lang="scss" scoped>
.selected__container {
    height: 100%;
    overflow: hidden;
}
.list {
    background: var(--units-list-bg);
}
</style>