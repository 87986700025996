<template>
    <div class="list__item item_view_unit"
         :class="{
            'item_active': isActive,
            'item_view_unit__group': subShipments.length
         }"
         :data-group-id="groupId" :data-shipment-id="shipmentId"
         @click.stop="setUnitActive"
         @mouseover="showMenu = true" @mouseleave="showMenu = false"
    >
        <ShipmentList_item_icons :shipmentId="shipmentId" :indicators="indicators"/>

        <div class="list__title-line">
            <h4 class="list__title">
                <spna class="list__title-icon" :class="shipmentStatus.class">
                    <SvgIcon :name="shipmentStatus.icon" class="icon"/>
                </spna>
                <span class="list__title-info" :title="shipmentName">
                    {{shipmentName}}
                </span>
            </h4>
            <UnitsList_unit_timeago :unit-id="unitId" v-if="shipment?.status !== 'completed'"/><!-- ! -->
            <ShipmentList_completed_date :shipmentId="shipmentId" v-if="shipment?.status === 'completed'"/>
        </div>
        <div class="list__address-line">
            <UnitsList_unit_address :unit-id="unitId" /><!-- ! -->
            <UnitsList_unit__status :unit-id="unitId" v-if="false"/><!-- ! -->
        </div>
        <div class="item_view_unit__dropdown" v-if="subShipments.length">
            <button class="button button_command" @click="showSubUnits = !showSubUnits">
                <SvgIcon :name="showSubUnitsButtonIcon" class="icon"/>
            </button>
        </div>
        <ShipmentList_menu
            :shipmentId="shipmentId"
            v-if="(showMenu || modalsWindows?.popupOutput )"
        />
    </div>
    <div class="item_view_unit__group--active" v-show="showSubUnits" v-if="subShipments.length && false">
        <template v-for="unit in subShipments" :key="unit.unitId">
            <component :is="'ShipmentList_unit'+unit.type" v-bind="unit"/>
        </template>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";

    import UnitsList_unit_timeago from "@/components/units/UnitsList_unit_timeago.vue";
    import UnitsList_unit_address from "@/components/units/UnitsList_unit_address.vue";
    import UnitsList_unit__status from "@/components/units/UnitsList_unit__status.vue";
    import ShipmentList_completed_date from "@/components/shipment/ShipmentList_completed_date";
    //import ShipmentList_menu from "@/components/units/ShipmentList_menu.vue";
    import ShipmentList_menu from "@/components/shipment/ShipmentList_menu.vue";
    import ShipmentList_item_icons from "@/components/shipment/ShipmentList_item_icons.vue";
    import {computed} from "vue";

    //unit types
    import UnitsList_unit from "@/components/units/UnitsList_unit.intuit.vue"
    import UnitsList_unit_xpower from "@/components/units/UnitsList_unit_xpower.intuit.vue"
    import UnitsList_unit_xlink from "@/components/units/UnitsList_unit_xlink.intuit.vue"
    import UnitsList_unit_xtag from "@/components/units/UnitsList_unit_xtag.intuit.vue"

    const shipmentStatus = {
        'started':{
            class: 'list__status--started',
            icon: 'from'
        },
        'accepted':{
            class: 'list__status--completed--planed',
            icon: 'common__checkbox'
        },
        'completed':{
            class: 'list__status--completed',
            icon: 'common__checkbox'
        },
        'cancelled':{
            class: 'list__status--completed',
            icon: 'common__checkbox'
        },
        'planed':{
            class: 'list__status--planed',
            icon: 'clock'
        },
        'active':{
            class: 'list__status--planed',
            icon: 'clock'
        },
    }

    export default {
        name: 'UnitsList_unit',
        inject: ['setRoute'],
        components: {
            ShipmentList_item_icons,
            UnitsList_unit_address,
            UnitsList_unit_timeago,
            UnitsList_unit__status,
            ShipmentList_completed_date,
            ShipmentList_menu,
            UnitsList_unit,
            UnitsList_unit_xpower,
            UnitsList_unit_xlink,
            UnitsList_unit_xtag,
        },
        props: {
            'shipmentId': [String, Number],
            'groupId': [String, Number],
        },
        data() {
            return {
                modalsWindows: {},
                showMenu: false,
                showSubUnits: false,
            }
        },
        provide() {
            return {
                modalsWindows: computed(() => this.modalsWindows)
            }
        },
        computed: {
            ...mapGetters([
                "getTimeToday",
                "unitsByIds",
                "getShipments",
                "getUserStorageKey",
            ]),
            routeName() {
                return this.$route.name
            },
            routeFilter() {
                return this.$route.params
            },
            routeQueryFilter() {
                return this.$route.query
            },
            shipment() {
                return this.getShipments?.find(s => s.id == this.shipmentId)
            },
            shipmentName(){
                return this.shipment?.name || ''
            },
            unit(){
                return this.unitsByIds[this.shipment?.unit_id]
            },
            unitId() {
                return this.unit?.id
            },
            indicators() {
                return []
            },
            isActive(){
                return this.routeFilter.shipmentId == this.shipmentId
            },
            showSubUnitsButtonIcon() {
                return `vector-` + (this.showSubUnits ? 'top' : 'down')
            },
            shipmentStatus() {
                return shipmentStatus[this.shipment?.status || 'planed']
            },
            subShipments() {
                return [
                    // {xid: this.groupId + unit.id, groupId: this.groupId, type, unitId: unit.id, parentId: this.unitId}
                ]
            }
        },
        watch: {
            isActive(newVl) {
                if (!newVl) {
                    this.showSubUnits = false
                }
            },
        },
        methods: {
            ...mapMutations([
            ]),
            ...mapActions([
            ]),
            setUnitActive(){
                this.setRoute.shipmentId(this.$route, this.$router, this.shipmentId)
            },
        },
        updated() {
            // console.log(`unit ${this.unitId} updated`);
        },
        mounted() {
        },
        beforeUnmount() {
        },
    }
</script>
<style lang="scss" scoped>
.list {
    .list__title{
        display: flex;
        align-items: center;
        gap: 5px;
    }
    &__title-info {
        display: inline-block;
        width: min-content;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    &__title-icon {
        width: 15px;
        height: 15px;
        min-width: 15px;
        min-height: 15px;
        border-radius: 100%;
        background: black;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
            width: 75%;
            height: 75%;
        }
    }
    &__status--started{
        color: white;
        background: #047AFB;
    }
    &__status--completed{
        color: white;
        background: #1EB60B;
    }
    &__status--planed{
        color: white;
        background: #A3A3A3;
    }
}
</style>
