const RESOURCE_NAME = '/shipments';
export default axios => ({
    shipmentAlerts(id, params) {
        return axios.get(`${RESOURCE_NAME}/${id}/alerts`, {params});
    },
    shipmentAlertsPeriod(id, from, to, params) {
        return axios.get(`${RESOURCE_NAME}/${id}/alerts/${from}/${to}`, {params});
    },
    shipmentAlertsDate(id, date, params) {
        return axios.get(`${RESOURCE_NAME}/${id}/alerts/${date}`, {params});
    }
});