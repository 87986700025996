//import http from "./api/http-common";
import httpAuth from "./api/http-auth";

import Auth from "./api/auth";
import Init from "./api/init";

import Upload from "./api/upload";
import Google from "./api/google";

import User from './api/user';
import Users from './api/users';
import UsersObjectsTemplates from './api/users-objects-templates';
import Units from './api/units';
import UnitsHelper from './api/units-helper';
import Drivers from './api/drivers';
import Appointments from './api/appointments';
import UnitsGroups from './api/units-groups';
import Geozones from './api/geozones';
import GeozonesGroups from './api/geozones-groups';
import Geopoints from './api/geopoints';
import GeopointsGroups from './api/geopoints-groups';

import ReportsTemplates from './api/reports-templates';
import Reports from './api/reports';
import Notifications from './api/notifications';
import NotificationsEvents from './api/notifications-events';
import UnitsMsgs from './api/units-msgs';

import Trips from './api/trips';
import Parkings from './api/parkings';
import Idlings from './api/idlings';
import Events from './api/events';
import Screenshots from './api/screenshots';
import UnitsGroupsScreenshots from './api/units-groups-screenshots';
import VideoEvents from './api/video-events';
import VideoRequests from './api/video-requests';
import VideoRecords from './api/video-records';
import VideoStatuses from './api/video-statuses';
import Stops from './api/stops';
import Tracks from './api/tracks';
import Accidents from './api/accidents';

import UnitsHwTypes from './api/units-hw-types'

import Shipments from './api/shipments'
import ShipmentTemplates from './api/shipments-templates'
import ShipmentsNotificationsTemplates from './api/shipments-notifications-templates'
import ShipmentsAlerts from './api/shipments-alerts'
import ShipmentTracks from './api/shipments-tracks'
import ShipmentsDevices from './api/shipments-devices'
import TiveCarriers from './api/tive-carriers'
import ShipmentsAlertsPresets from './api/shipments-alerts-presets'
import ShipmentsAddressTemplates from './api/shipments-address-templates'
import ShipmentsTypes from './api/shipments-types'

import Maintenance from './api/maintenance'
import MaintenanceVendors from './api/maintenance-vendors'
import MaintenanceContacts from './api/maintenance-contacts'
import MaintenanceContactGroups from './api/maintenance-contact-groups'
import MaintenanceParts from './api/maintenance-parts'
import MaintenanceServiceTasks from './api/maintenance-service-tasks'
import MaintenanceIssues from './api/maintenance-issues'
import MaintenanceWorkOrders from './api/maintenance-work-orders'
import MaintenanceInspections from './api/maintenance-inspections'
import MaintenanceInspectionForms from './api/maintenance-inspections-forms'
import MaintenanceHistory from './api/maintenance-history'
import MaintenanceNotifications from './api/maintenance-notifications'
import MaintenanceExpenses from './api/maintenance-expenses'

import InsuranceReports from './api/insurance-reports'
import InsuranceForms from './api/insurance-forms'

import AccessRights from './api/access-rights'
import Locator from './api/locator'
import Weather from './api/weather'
import Statistics from "./api/statistics";
import Analytics from "./api/analytics";
import ObjectsAudit from "@/api/objects-audit";
import Commands from "@/api/commands";
import Customize from './api/customize';

import Exports from './api/exports';

import Locations from './api/locations';

import Entri from "./api/entri";
import Site from './api/site';

import config from "@/config";
httpAuth.defaults.baseURL = config.api.baseURL + '/' + (config?.api?.ver || '')
//console.log(httpAuth.defaults)

// Initialize all or API factories
const factories = {
    auth: Auth(),
    locations: Locations(),
    accessrights: AccessRights(),
    init: Init(),
    upload: Upload(),
    google: Google(),

    user: User(httpAuth),
    usersObjectsTemplates: UsersObjectsTemplates(httpAuth),

    unitshwtypes: UnitsHwTypes(httpAuth),
    unitshelper: UnitsHelper(httpAuth),

    maintenance: Maintenance(httpAuth),
    maintenancevendors: MaintenanceVendors(httpAuth),
    maintenancecontacts: MaintenanceContacts(httpAuth),
    maintenancecontactgroups: MaintenanceContactGroups(httpAuth),
    maintenanceparts: MaintenanceParts(httpAuth),
    maintenanceservicetasks: MaintenanceServiceTasks(httpAuth),
    maintenanceissues: MaintenanceIssues(httpAuth),
    maintenanceworkorders: MaintenanceWorkOrders(httpAuth),
    maintenanceinspections: MaintenanceInspections(httpAuth),
    maintenanceinspectionforms: MaintenanceInspectionForms(httpAuth),
    maintenancehistory: MaintenanceHistory(httpAuth),
    maintenancenotifications: MaintenanceNotifications(httpAuth),
    maintenanceexpenses: MaintenanceExpenses(httpAuth),

    insurancereports: InsuranceReports(httpAuth),
    insuranceforms: InsuranceForms(httpAuth),

    users: Users(httpAuth),
    units: Units(httpAuth),
    drivers: Drivers(httpAuth),
    appointments: Appointments(httpAuth),
    unitsgroups: UnitsGroups(httpAuth),
    geozones: Geozones(httpAuth),
    geozonesgroups: GeozonesGroups(httpAuth),
    geopoints: Geopoints(httpAuth),
    geopointsgroups: GeopointsGroups(httpAuth),

    reportstemplates: ReportsTemplates(httpAuth),
    reports: Reports(httpAuth),
    notifications: Notifications(httpAuth),
    commands: Commands(httpAuth),

    accidents: Accidents(httpAuth),
    trips: Trips(httpAuth),
    parkings: Parkings(httpAuth),
    idlings: Idlings(httpAuth),
    events: Events(httpAuth),
    screenshots: Screenshots(httpAuth),
    UnitsGroupsScreenshots: UnitsGroupsScreenshots(httpAuth),
    videoEvents: VideoEvents(httpAuth),
    videoRequests: VideoRequests(httpAuth),
    videoRecords: VideoRecords(httpAuth),
    stops: Stops(httpAuth),
    tracks: Tracks(httpAuth),
    notificationsevents: NotificationsEvents(httpAuth),

    unitsmsgs: UnitsMsgs(httpAuth),
    videoStatuses: VideoStatuses(httpAuth),

    objectsaudit: ObjectsAudit(httpAuth),
    customize: Customize(httpAuth),

    shipments: Shipments(httpAuth),
    shipmentsTemplates: ShipmentTemplates(httpAuth),
    shipmentsNotificationsTemplates: ShipmentsNotificationsTemplates(httpAuth),
    shipmentsAlerts: ShipmentsAlerts(httpAuth),
    shipmentsTracks: ShipmentTracks(httpAuth),
    shipmentsDevices: ShipmentsDevices(httpAuth),
    tivecarriers: TiveCarriers(httpAuth),
    shipmentsAlertsPresets: ShipmentsAlertsPresets(httpAuth),
    shipmentsAddressTemplates: ShipmentsAddressTemplates(httpAuth),
    shipmentstypes: ShipmentsTypes(httpAuth),

    locator: Locator(httpAuth),
    weather: Weather(httpAuth),
    statistics: Statistics(httpAuth),
    analytics: Analytics(httpAuth),
    exports: Exports(httpAuth),
    entri: Entri(httpAuth),

    site: Site(httpAuth),
};

// Vue.$api = factories;
// store.$api = factories

export default factories;
