// import Vue from 'vue'
// import consts from "@/consts"
// import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
const changedField = 'changed__time';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        shipmentsAddressTemplatesFullLoad: false,
        shipmentsAddressTemplatesLiteLoad: false,
        shipmentsAddressTemplates: [],
    },
    actions: {
        fetchShipmentsAddressTemplates/*all*/({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchShipmentsAddressTemplates', time: Date.now() / 1000})

                const params = getApiProps('shipmentsAddressTemplates', args)
                this.$api.shipmentsAddressTemplates.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateShipmentsAddressTemplates', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        commit('setShipmentsAddressTemplatesLiteLoad', true)
                        commit('setShipmentsAddressTemplatesFullLoad', true)
                        dispatch('setLastCall', {name: 'fetchShipmentsAddressTemplates', inprogress: false})
                    });
            })
        },
        fetchShipmentsAddressTemplate/*all*/({dispatch, commit, getters}, id) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchShipmentsAddressTemplate', time: Date.now() / 1000})

                const params = getApiProps('shipmentsAddressTemplates')
                this.$api.shipmentsAddressTemplates.find(id,{...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response?.data?.error && !response?.data?.errors) {
                            commit('updateShipmentsAddressTemplate', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchShipmentsAddressTemplate', inprogress: false})
                    });
            })
        },

        saveShipmentsAddressTemplate({dispatch}, location) {
            let fn = (location.id) ? 'updateShipmentsAddressTemplate' : 'createShipmentsAddressTemplate'
            return dispatch(fn, location);
        },
        createShipmentsAddressTemplate({commit}, location) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('shipmentsAddressTemplates')
                this.$api.shipmentsAddressTemplates.create(location, params)
                    .then((response) => {
                        if (response.status < 400 && !response?.data?.error && !response?.data?.errors) {
                            commit('updateShipmentsAddressTemplate', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateShipmentsAddressTemplate({commit}, location) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('shipmentsAddressTemplates')
                this.$api.shipmentsAddressTemplates.update(location.id, location, params)
                    .then((response) => {
                        if (response.status < 400 && !response?.data?.error && !response?.data?.errors) {
                            commit('updateShipmentsAddressTemplate', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteShipmentsAddressTemplate({commit}, locationId) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('shipmentsAddressTemplates')
                this.$api.shipmentsAddressTemplates.delete(locationId, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data.error)) {
                            if (!response.data) commit('deleteShipmentsAddressTemplate', locationId)
                            else commit('updateShipmentsAddressTemplate', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations: {
        setShipmentsAddressTemplatesFullLoad(state, FullLoad) {
            state.shipmentsAddressTemplatesFullLoad = state.shipmentsAddressTemplatesFullLoad || FullLoad
        },
        setShipmentsAddressTemplatesLiteLoad(state, LitaLoad) {
            state.shipmentsAddressTemplatesLiteLoad = state.shipmentsAddressTemplatesLiteLoad || LitaLoad
        },

        setShipmentsAddressTemplates(state, nShipmentsAddressTemplates) {
            nShipmentsAddressTemplates = nShipmentsAddressTemplates.map(u => {
                if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                return u //Object.freeze(u)
            })
            nShipmentsAddressTemplates.sort(sortByName)
            state.shipmentsAddressTemplates = nShipmentsAddressTemplates
        },

        updateShipmentsAddressTemplates(state, nShipmentsAddressTemplates) {
            if (!state.shipmentsAddressTemplates.length) {
                nShipmentsAddressTemplates = nShipmentsAddressTemplates.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nShipmentsAddressTemplates.sort(sortByName)
                state.shipmentsAddressTemplates = nShipmentsAddressTemplates
                // const chunks = arraySplitIntoChunks(nShipmentsAddressTemplates)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.shipmentsAddressTemplates.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nShipmentsAddressTemplates.forEach(function (nLocation) {
                if (nLocation?.name) nLocation.name_ = nLocation.name.toLocaleLowerCase()
                let i = state.shipmentsAddressTemplates.findIndex(u => (u.id == nLocation.id))
                if (i < 0) {
                    state.shipmentsAddressTemplates.push(nLocation) //(Object.freeze(nLocation))
                } else
                if (!state.shipmentsAddressTemplatesFullLoad || state.shipmentsAddressTemplates[i][changedField] != nLocation[changedField]) {
                    updateObjectByDiff(state.shipmentsAddressTemplates[i], nLocation)
                    // delete nLocation.id
                    // nLocation = {...state.shipmentsAddressTemplates[i], ...nLocation}
                    // state.shipmentsAddressTemplates[i] = nLocation //Object.freeze(nLocation)
                }
            })

        },
        filterShipmentsAddressTemplates(state, nShipmentsAddressTemplates) {
            // let Ids = state.shipmentsAddressTemplates.map(u=> u.id)
            let nIds = nShipmentsAddressTemplates.map(u => u.id)
            let removedIds = state.shipmentsAddressTemplates.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.shipmentsAddressTemplates.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.shipmentsAddressTemplates.splice(i, 1)
                }
            })
        },
        updateShipmentsAddressTemplate(state, nLocation) {
            if (nLocation?.name) nLocation.name_ = nLocation.name.toLocaleLowerCase()
            let i = state.shipmentsAddressTemplates.findIndex(u => (u.id == nLocation.id))
            if (i < 0) {
                state.shipmentsAddressTemplates.push(nLocation) //(Object.freeze(nLocation))
            } else
            //if (!state.shipmentsAddressTemplatesFullLoad || state.shipmentsAddressTemplates[i][changedField] != nLocation[changedField])
            {
                updateObjectByDiff(state.shipmentsAddressTemplates[i], nLocation)
                // delete nLocation.id
                // nLocation = {...state.shipmentsAddressTemplates[i], ...nLocation}
                // state.shipmentsAddressTemplates[i] = nLocation //Object.freeze(nLocation)
            }
        },
        deleteShipmentsAddressTemplate(state, locationId) {
            let i = state.shipmentsAddressTemplates.findIndex(u => (u.id == locationId))
            if (i != -1) {
                state.shipmentsAddressTemplates.splice(i, 1)
            }
        },

        clearShipmentsAddressTemplates(state) {
            state.shipmentsAddressTemplates = []
            state.shipmentsAddressTemplatesFullLoad = false
        },
    },
    getters: {
        isShipmentsAddressTemplatesFullLoad(state) {
            return state.shipmentsAddressTemplatesFullLoad
        },
        isShipmentsAddressTemplatesLiteLoad(state) {
            return state.shipmentsAddressTemplatesLiteLoad
        },
        getShipmentsAddressTemplates(state) {
            return state.shipmentsAddressTemplates
        },
    }
}
