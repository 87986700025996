<template>
    <button class="button button_view_icon button_theme_base mtb__-4"
            :disabled="disabled"
            @click.stop="setUnitFns"
    >
        <span class="icon">
            <span class="icon__symbol">
                <SvgIcon class="icon" name="common__edit_lg" />
            </span>
        </span>
    </button>
</template>
<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";

    export default {
        name: 'ShipmentList_menu_edit',
        props: {
            shipmentId: [String, Number],
            disabled: Boolean,
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
            ]),
            setRoute() {
                return this.$route.meta?.setRoute
            },
        },
        methods: {
            ...mapMutations([
            ]),
            ...mapActions([
            ]),
            setUnitFns() {
                this.setRoute.shipmentEditView(this.$route, this.$router, this.shipmentId);
            },
        },
    }
</script>