// import Vue from 'vue'
// import consts from "@/consts"
// import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
const changedField = 'changed__time';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        shipmentsNotificationsTemplatesFullLoad: false,
        shipmentsNotificationsTemplatesLiteLoad: false,
        shipmentsNotificationsTemplates: [],
    },
    actions: {
        fetchShipmentsNotificationTemplates/*all*/({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchShipmentsNotificationTemplates', time: Date.now() / 1000})

                const params = getApiProps('shipmentsNotificationsTemplates', args)
                this.$api.shipmentsNotificationsTemplates.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateShipmentsNotificationTemplates', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        commit('setShipmentsNotificationTemplatesLiteLoad', true)
                        commit('setShipmentsNotificationTemplatesFullLoad', true)
                        dispatch('setLastCall', {name: 'fetchShipmentsNotificationTemplates', inprogress: false})
                    });
            })
        },

        saveShipmentsNotificationTemplate({dispatch}, shipmentsNotificationsTemplate) {
            let fn = (shipmentsNotificationsTemplate.id) ? 'updateShipmentsNotificationTemplate' : 'createShipmentsNotificationTemplate'
            return dispatch(fn, shipmentsNotificationsTemplate);
        },
        createShipmentsNotificationTemplate({commit}, shipmentsNotificationsTemplate) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('shipmentsNotificationsTemplates')
                this.$api.shipmentsNotificationsTemplates.create(shipmentsNotificationsTemplate, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateShipmentsNotificationTemplate', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateShipmentsNotificationTemplate({commit}, shipmentsNotificationsTemplate) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('shipmentsNotificationsTemplates')
                this.$api.shipmentsNotificationsTemplates.update(shipmentsNotificationsTemplate.id, shipmentsNotificationsTemplate, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateShipmentsNotificationTemplate', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteShipmentsNotificationTemplate({commit}, shipmentsNotificationsTemplateId) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('shipmentsNotificationsTemplates')
                this.$api.shipmentsNotificationsTemplates.delete(shipmentsNotificationsTemplateId, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (!response.data || !response.data.error) {
                            if (!response.data) commit('deleteShipmentsNotificationTemplate', shipmentsNotificationsTemplateId)
                            else commit('updateShipmentsNotificationTemplate', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations: {
        setShipmentsNotificationTemplatesFullLoad(state, FullLoad) {
            state.shipmentsNotificationsTemplatesFullLoad = state.shipmentsNotificationsTemplatesFullLoad || FullLoad
        },
        setShipmentsNotificationTemplatesLiteLoad(state, LitaLoad) {
            state.shipmentsNotificationsTemplatesLiteLoad = state.shipmentsNotificationsTemplatesLiteLoad || LitaLoad
        },

        setShipmentsNotificationTemplates(state, nShipmentsNotificationTemplates) {
            nShipmentsNotificationTemplates = nShipmentsNotificationTemplates.map(u => {
                if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                return u //Object.freeze(u)
            })
            nShipmentsNotificationTemplates.sort(sortByName)
            state.shipmentsNotificationsTemplates = nShipmentsNotificationTemplates
        },

        updateShipmentsNotificationTemplates(state, nShipmentsNotificationTemplates) {
            if (!state.shipmentsNotificationsTemplates.length) {
                nShipmentsNotificationTemplates = nShipmentsNotificationTemplates.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nShipmentsNotificationTemplates.sort(sortByName)
                state.shipmentsNotificationsTemplates = nShipmentsNotificationTemplates
                // const chunks = arraySplitIntoChunks(nShipmentsNotificationTemplates)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.shipmentsNotificationsTemplates.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nShipmentsNotificationTemplates.forEach(function (nShipmentsNotificationTemplate) {
                if (nShipmentsNotificationTemplate?.name) nShipmentsNotificationTemplate.name_ = nShipmentsNotificationTemplate.name.toLocaleLowerCase()
                let i = state.shipmentsNotificationsTemplates.findIndex(u => (u.id == nShipmentsNotificationTemplate.id))
                if (i < 0) {
                    state.shipmentsNotificationsTemplates.push(nShipmentsNotificationTemplate) //(Object.freeze(nShipmentsNotificationTemplate))
                } else
                if (!state.shipmentsNotificationsTemplatesFullLoad || state.shipmentsNotificationsTemplates[i][changedField] != nShipmentsNotificationTemplate[changedField]) {
                    updateObjectByDiff(state.shipmentsNotificationsTemplates[i], nShipmentsNotificationTemplate)
                    // delete nShipmentsNotificationTemplate.shipmentsNotificationsTemplateId
                    // nShipmentsNotificationTemplate = {...state.shipmentsNotificationsTemplates[i], ...nShipmentsNotificationTemplate}
                    // state.shipmentsNotificationsTemplates[i] = nShipmentsNotificationTemplate //Object.freeze(nShipmentsNotificationTemplate)
                }
            })

        },
        filterShipmentsNotificationTemplates(state, nShipmentsNotificationTemplates) {
            // let Ids = state.shipmentsNotificationsTemplates.map(u=> u.shipmentsNotificationsTemplateId)
            let nIds = nShipmentsNotificationTemplates.map(u => u.id)
            let removedIds = state.shipmentsNotificationsTemplates.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.shipmentsNotificationsTemplates.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.shipmentsNotificationsTemplates.splice(i, 1)
                }
            })
        },
        updateShipmentsNotificationTemplate(state, nShipmentsNotificationTemplate) {
            if (nShipmentsNotificationTemplate?.name) nShipmentsNotificationTemplate.name_ = nShipmentsNotificationTemplate.name.toLocaleLowerCase()
            let i = state.shipmentsNotificationsTemplates.findIndex(u => (u.id == nShipmentsNotificationTemplate.id))
            if (i < 0) {
                state.shipmentsNotificationsTemplates.push(nShipmentsNotificationTemplate) //(Object.freeze(nShipmentsNotificationTemplate))
            } else
            //if (!state.shipmentsNotificationsTemplatesFullLoad || state.shipmentsNotificationsTemplates[i][changedField] != nShipmentsNotificationTemplate[changedField])
            {
                updateObjectByDiff(state.shipmentsNotificationsTemplates[i], nShipmentsNotificationTemplate)
                // delete nShipmentsNotificationTemplate.shipmentsNotificationsTemplateId
                // nShipmentsNotificationTemplate = {...state.shipmentsNotificationsTemplates[i], ...nShipmentsNotificationTemplate}
                // state.shipmentsNotificationsTemplates[i] = nShipmentsNotificationTemplate //Object.freeze(nShipmentsNotificationTemplate)
            }
        },
        deleteShipmentsNotificationTemplate(state, shipmentsNotificationsTemplateId) {
            let i = state.shipmentsNotificationsTemplates.findIndex(u => (u.id == shipmentsNotificationsTemplateId))
            if (i != -1) {
                state.shipmentsNotificationsTemplates.splice(i, 1)
            }
        },

        clearShipmentsNotificationTemplates(state) {
            state.shipmentsNotificationsTemplates = []
            state.shipmentsNotificationsTemplatesFullLoad = false
        },
    },
    getters: {
        isShipmentsNotificationTemplatesFullLoad(state) {
            return state.shipmentsNotificationsTemplatesFullLoad
        },
        isShipmentsNotificationTemplatesLiteLoad(state) {
            return state.shipmentsNotificationsTemplatesLiteLoad
        },
        getShipmentsNotificationTemplates(state) {
            return state.shipmentsNotificationsTemplates
        },
    }
}
