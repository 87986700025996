<template>
    <button class="button button_view_icon button_theme_base mtb__-4"
            :disabled="disabled"
            @click.stop="setUnitFns"
    >
        <span class="icon">
            <span class="icon__symbol">
                <SvgIcon class="icon" name="common__delete_lg" />
            </span>
        </span>
    </button>
</template>
<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";

    export default {
        name: 'ShipmentList_menu_edit',
        inject: ['toast'],
        props: {
            shipmentId: [String, Number],
            disabled: Boolean,
        },
        data() {
            return {
                modalWindows: {},
            }
        },
        computed: {
            ...mapGetters([
            ]),
            setRoute() {
                return this.$route.meta?.setRoute
            },
        },
        methods: {
            ...mapMutations([
            ]),
            ...mapActions([
                "deleteShipment"
            ]),
            modalClose() {
                this.modalWindows = {}
            },
            showModal() {
                this.modalWindows = {
                    'onDelete': {
                        props:{
                            title: 'Hello',
                            buttons:[
                                {id: 'ok', fn: () => this.setUnitFns()},
                                {id: 'cancel', fn: () => this.modalClose()},
                            ]
                        }
                    }
                }
            },
            setUnitFns() {
                this.deleteShipment(this.shipmentId)
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data.error)) {
                            this.toast.success(this.$t("success!"))
                        } else {
                            this.toast.error(this.$t("error!"))
                            console.error(response);
                        }
                    })
                    .catch((erorr) => {
                        console.error(erorr);
                        this.toast.error(this.$t("error!"))
                    });
            },
        },
        mounted() {
        }
    }
</script>